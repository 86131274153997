<template>
    <div id="layers-page">
       
        <vs-alert v-if="!this.can_see_page" color="warning" :title="$t('AccessDeniedTitle')" active="true">
          <p>{{ $t(this.AccessDeniedMessage) }}<br>[{{ this.AccessDeniedInfos }}]</p>
        </vs-alert>

        <template v-else>

          <!-- quotas -->
          <vx-card :title="this.$t('subscriptionUsage')" remove-card-action>
            <subscription-quotas :actualisation="incrementedSubscriptionCheck" display users/>
          </vx-card>

          <!-- Ajout d'un user si quota OK -->
          <vx-card class="mt-8" :title="this.$t('userCreation')" remove-card-action>
            <vs-alert v-if="this.$store.state.subscriptionUsage.usersAvailable <= 0" active="true" color="warning">{{ $t('subscriptionUsersExceeded') }}</vs-alert>
            <template v-else>
              <!-- user_email -->
              <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                  <span>Email</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <vs-input type="email" class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border 
                    v-model="userCreation_user_email"
                    @keydown.enter="checkNewUserByEmail()"
                    @blur="checkNewUserByEmail()"
                    :success="userCreation_email_checked && userCreation_email_valid" 
                    :danger="userCreation_email_checked && !userCreation_email_valid" 
                    :success-text="$t('validEmail')"
                    :danger-text="$t('invalidEmail')"
                    val-icon-success="icon-check"
                    val-icon-pack="feather"
                    val-icon-danger="icon-x"
                  />
                  <!-- alertes après vérification -->
                  <template v-if="userCreationChecksDone">
                    <!-- si utilisateur déjà présent -->
                    <vs-alert v-if="userCreation_user_exists_on_this_portal" active="true" color="danger">{{ $t('user_exists_on_this_portal') }}</vs-alert>
                    <template v-else>
                      <!-- sinon ... -->
                      <vs-alert v-if="userCreation_email_checked && userCreation_email_valid && userCreation_account_exists" active="true" color="warning">{{ $t('account_exists') }}</vs-alert>
                      <vs-alert v-if="userCreation_email_checked && userCreation_email_valid && !userCreation_account_exists" active="true" color="primary">{{ $t('accountWillBeCreated') }}</vs-alert>
                    </template>
                  </template>
                </div>
              </div>
              <template v-if="userCreation_user_email !== '' && userCreation_email_checked && userCreation_email_valid">
                <!-- first_name -->
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full">
                    <span>{{$t('userFirstName')}}</span>
                  </div>
                  <div class="vx-col sm:w-4/5 w-full">
                    <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border v-model="userCreation_first_name"
                    :disabled="userCreation_account_exists"
                    @blur="prepareForm()"/>
                  </div>
                </div>
                <!-- last_name -->
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full">
                    <span>{{$t('userLastName')}}</span>
                  </div>
                  <div class="vx-col sm:w-4/5 w-full">
                    <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border v-model="userCreation_last_name"
                    :disabled="userCreation_account_exists"
                    @blur="prepareForm()"/>
                  </div>
                </div>
                
                <div class="vx-row">
                  <div class="vx-col sm:w-4/5 w-full ml-auto">
                    <vs-button @click="addPortalUserConfirm()" class="mr-3 mb-2"
                    :disabled="userCreationDisabled">{{$t('userCreationSubmit')}} </vs-button>
                  </div>
                </div>
              </template>
            </template>
          </vx-card>


          <!-- liste des users -->
          <div id="data-list-list-view" class="data-list-container mt-8">

            <user-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

            <vs-table ref="table" v-model="selected" search :data="this.portalUsers">

              <template slot="thead">
                <vs-th sort-key="user_id"></vs-th>
                <vs-th sort-key="first_name">{{$t('userFirstName')}}</vs-th>
                <vs-th sort-key="last_name">{{$t('userLastName')}}</vs-th>
                <vs-th>{{$t('Action')}}</vs-th>
              </template>

                <template slot-scope="{data}">
                  <tbody>
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <template v-if="data[indextr].user_id != 1">

                        <vs-td>
                          <user-avatar 
                            :user="tr"
                            size="large"
                            :key="'avatar_'+tr.user_id"
                          />
                        </vs-td>

                        <vs-td>
                          <p class="first_name">{{ tr.first_name | capitalize }}</p>
                        </vs-td>

                        <vs-td>
                          <p class="last_name">{{ tr.last_name | capitalize }}</p>
                        </vs-td>

                        <vs-td class="whitespace-no-wrap">
                          <feather-icon icon="EditIcon" svgClasses="ml-2 w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
                        </vs-td>
                      </template>
                    </vs-tr>
                  </tbody>
                </template>
            </vs-table>
          </div>
        </template>

    </div>
</template>
<script>
import axios from '@/axios.js'
import UserSidebar from './components/UserSidebar.vue'
import SubscriptionQuotas from './subscription/quotas.vue'
import moment from 'moment' // for date comparaison
import logActivity from '@/logActivity.js'

export default {
  components: {
    UserSidebar, SubscriptionQuotas
  },
  data () {
    return {
      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getPortalUsers: 'getPortalUsers',
      getPortalSubscriptionUsage: 'getPortalSubscriptionUsage',
      getUserByEmail: 'getUserByEmail',
      addNewUser: 'addNewUser',
      addPortalUserAllocation: 'addPortalUserAllocation',
      getUserPortalCaps: 'getUserPortalCaps',

      // ROYBON / Subscription usage
      incrementedSubscriptionCheck:0,

      // ROYBON / Users
      portalUsers: [],

      // ROYBON / Avatar
      userAvatar: {},
      thumbName: 'thumb_150_150.jpg',

      // ROYBON / User creation
      userCreation_first_name: '',
      userCreation_last_name: '',
      userCreation_user_email: '',
      userCreation_email_checked: false,
      userCreation_email_valid: false,
      userCreation_account_exists: true,
      userCreation_user_exists_on_this_portal: false,
      userCreation_user_id: 0,
      userCreationChecksDone: false,

      // ROYBON / Data list
      selected: [],
      itemsPerPage: 25,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      // ROYBON / Interprétation des droits
      can_see_page: null,
      AccessDeniedMessage: '',
      AccessDeniedInfos: ''
    }
  },
  methods: {    
    // ROYBON / Confirmation d'ajout de l'utilisateur
    addPortalUserConfirm () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: this.$t('Confirm'),
        text: this.$t('accountCreationConfirmationMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.addPortalUserAction
      })
    },
    // ROYBON / Action d'ajout de l'utilisateur
    addPortalUserAction () {
      if (this.userCreation_account_exists) {
        this.allocateUserToPortal()
      } else {
        this.createAndAllocateUserToPortal()
      }
    },
    // ROYBON / Création d'un compte
    createAndAllocateUserToPortal () {
      this.userCreation_user_id = 0
      const first_name = encodeURIComponent(this.userCreation_first_name.toLowerCase().trim())
      const last_name = encodeURIComponent(this.userCreation_last_name.toLowerCase().trim())
      const user_email = encodeURIComponent(this.userCreation_user_email.toLowerCase().trim())

      //const params = `first_name=${first_name}&last_name=${last_name}&user_email=${user_email}`
      const params = new FormData()
      params.set('first_name', `${first_name}`)
      params.set('last_name', `${last_name}`)
      params.set('user_email', `${user_email}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.addNewUser}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => {
          if (response.data[0] !== 'error') {
            const new_user_id = parseInt(response.data.user_id)
            if (!isNaN(new_user_id)) {
              // success
              this.$vs.notify({
                color:'success',
                text:this.$t('userCreationSuccess')
              })
              this.userCreation_user_id = new_user_id

              // allocation du user à ce portal
              this.allocateUserToPortal()
              
              // log l'activité du user
              logActivity.add('user_creation', new_user_id)

              // envoi du mail avec la procédure de connexion
              /////////// TODO ///////////
            }            
          } else {
            // error
            this.$vs.notify({
              color:'danger',
              text:this.$t('userCreationError')
            })
          }
        })
        .catch((error)   => { 
          console.log(error) 
          // error
          this.$vs.notify({
            color:'danger',
            text:this.$t('userCreationError')
          })
        })
    },

    // ROYBON / Création d'un compte
    allocateUserToPortal () {     
      // allocation au portal
      //const params = `portal_id=${this.$store.state.portal_id}&user_id=${this.userCreation_user_id}`
      const params = new FormData()
      params.set('portal_id', `${this.$store.state.portal_id}`)
      params.set('user_id', `${this.userCreation_user_id}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.addPortalUserAllocation}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // success
            this.$vs.notify({
              color:'success',
              text:this.$t('userPortalAllocationSuccess')
            })
            this.buildUsersList()
            // log l'activité du user
            logActivity.add('user_portal_allocation', this.userCreation_user_id)
          } else {
            // error
            this.$vs.notify({
              color:'danger',
              text:this.$t('userPortalAllocationError')
            })
          }
        })
        .catch((error)   => { 
          console.log(error) 
          // error
          this.$vs.notify({
            color:'danger',
            text:this.$t('userPortalAllocationError')
          })
        })
    },

    // ROYBON / Obtient les infos d'un user connu (dans un autre portail par exemple) sur la base de son email
    checkNewUserByEmail () {
      this.userCreationChecksDone = false 
      // clear previous values
      this.userCreation_first_name = this.userCreation_last_name = this.userCreation_account_exists = ''
      // sanitize email
      this.userCreation_email_checked = false
      this.userCreation_user_email = this.sanitizeEmail(this.userCreation_user_email)
      this.userCreation_email_checked = true
      // check if account exists
      this.userCreation_account_exists = false
      const params = `user_email=${encodeURIComponent(this.userCreation_user_email)}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserByEmail}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (null !== response) {
            if (null !== response.data) {
              if (!isNaN(response.data.user_id)) {
                this.userCreation_first_name =  this.capitalize(response.data.first_name)
                this.userCreation_last_name =   this.capitalize(response.data.last_name)
                this.userCreation_user_id =     response.data.user_id
                this.userCreation_account_exists = true
                // check si déjà affecté au portal actuel
                this.checkExistingAccountPortalAllocation()
              } else {
                this.userCreation_account_exists = false
                this.userCreationChecksDone = true 
              }
            } else {
              this.userCreation_account_exists = false
              this.userCreationChecksDone = true 
            }
          }
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Si compte existe, vérifie si déjà actif sur le portal actuel
    checkExistingAccountPortalAllocation () {
      this.userCreation_user_exists_on_this_portal = false
      const params = `portal_id=${this.$store.state.portal_id}&user_id=${this.userCreation_user_id}&user_hash=${this.$store.state.AppActiveUser.user_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserPortalCaps}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => {
          if (response.data !== null && response.data != 'null') {
            if (response.data.can_portal_access === '1') {
              this.userCreation_user_exists_on_this_portal = true
            }
          }
          this.userCreationChecksDone = true 
        })
        .catch((error)   => { console.log(error) })     
    },

    // ROYBON / Fonction de nettoyage de l'email
    sanitizeEmail (value) {
      value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      value = value.replace(/ /g, '')
      value = value.trim()
      value = value.toLowerCase()
      const emailParts = value.split(/@/)
      if (emailParts.length !== 2) {
        this.userCreation_email_valid = false
      }
      const username = emailParts[0]
      const domain = emailParts[1]
      const checked_value = `${username}@${domain}`
      const re = /\S+@\S+\.\S+/
      if (re.test(checked_value)) {
        const filter = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/
        this.userCreation_email_valid = (String(checked_value).search(filter) !== -1)
      } else {
        this.userCreation_email_valid = false
      }
      return value
    },

    // ROYBON / génère un username complet
    userTextAvatar (tr) {
      let firstName = tr.first_name || '-'
      let lastName = tr.last_name || '-'
      firstName = firstName.substring(0, 1).toUpperCase()
      lastName = lastName.substring(0, 3).toUpperCase()
      return `${firstName}.${lastName}`
    },
    // ROYBON / génère une couleur unique pour un user_id
    avatarColor (user_id) {
      return `#${this.intToRGB(this.hashCode(user_id))}`
    },
    hashCode (str) {
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      return hash
    },
    intToRGB (i) {
      const c = (Math.pow(i, 2) & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()
      return '00000'.substring(0, 6 - c.length) + c
    },

    // ROYBON / Charge les images de avatars
    getAvatars () {
      for (const user in this.portalUsers) {
        const src = `${this.$appConfig.imgBaseUrl}/user/avatar/${this.portalUsers[user].user_id}/${this.thumbName}`
        this.$http.get(src)
          .then((response) => { 
            if (response.status === 200) {
              this.$set(this.userAvatar, this.portalUsers[user].user_id, src)
            }
          })
          .catch(() => {})
      }
    },

    // ROYBON / Fonction de nettoyage du titre
    sanitizeTitle (value) {
      const regex = /(<([^>]+)>)/ig
      value = value.replace(regex, '')
      value = value.trim()
      return value
    },
    editData (data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    getSizeColor (num) {
      if (num > 80)  return 'danger'
      if (num > 60)  return 'warning'
      if (num >= 40) return 'primary'
      if (num < 40)  return 'success'
      return 'primary'
    },
    getAllocationsColor (num) {
      if (num === '0')  return 'danger'
      if (num === '1')  return 'primary'
      if (num > 1)  return 'success'
      return 'primary'
    },
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
      this.buildUsersList()
      if (val === false) this.refreshSubscriptionQuotas()
    },

    // ROYBON / Force l'actualisation des quotas de l'abonnement
    refreshSubscriptionQuotas () {
      this.incrementedSubscriptionCheck = this.incrementedSubscriptionCheck + 1
    },

    // ROYBON / Liste les portals ayant accès à ce project
    buildUsersList () {
      const params = `portal_id=${this.$store.state.portal_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalUsers}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null' && typeof response.data == 'object') {
            this.portalUsers = response.data
            for (const key of Object.keys(this.portalUsers)) {
              const user = this.portalUsers[key]
              user.first_name = decodeURIComponent(user.first_name)
              user.last_name = decodeURIComponent(user.last_name)
              user.user_email = decodeURIComponent(user.user_email)
            }
          }
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Check si l'affichage de la page est autorisé
    canSeePage () {
      const gc =                this.$store.state.current_user_global_caps
      const poc =               this.$store.state.current_user_portal_caps
      const poc_start_date =    poc.can_portal_access_start_date
      const poc_end_date =      poc.can_portal_access_end_date
      if (gc.can_global_access === '1' && poc.can_portal_access === '1') {
        if (poc_start_date !== null && moment().isSameOrBefore(poc_start_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessNotYetOpened'
          this.AccessDeniedInfos = poc_start_date
        } else if (poc_end_date !== null && moment().subtract(1, 'd').isSameOrAfter(poc_end_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessExpired'
          this.AccessDeniedInfos = poc_end_date
        } else if (poc.can_portal_users_manage !== '1') {
          this.can_see_page = false
          this.AccessDeniedMessage = 'cannotManageUsers'
        } else {
          this.can_see_page = true
        }
      } else {
        this.can_see_page = false
        this.AccessDeniedMessage = 'PortalAccessDeniedFull' 
        this.AccessDeniedInfos = 'global or portal access invalid'
      }
    },

    // ROYBON / Capitalize first letter
    capitalize (value) {
      if (!value) return ''
      value = value.toString()
      const arr = value.split(' ')
      const capitalized_array = []
      arr.forEach((word) => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
        capitalized_array.push(capitalized)
      })
      return capitalized_array.join(' ')
    },

    // ROYBON / Sanitize des valeurs du formulaire
    prepareForm () {
      this.userCreation_first_name = this.sanitizeTitle(this.userCreation_first_name)
      this.userCreation_last_name = this.sanitizeTitle(this.userCreation_last_name)
      this.userCreation_last_name = this.sanitizeTitle(this.userCreation_last_name)
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.portalUsers.length
    },
    userCreationDisabled () {
      this.prepareForm()
      if (this.userCreation_first_name.length + this.userCreation_last_name.length < 1 || this.userCreation_user_exists_on_this_portal) {
        return true
      } else {
        return false
      }
    }
  },

  beforeMount () {
    this.canSeePage()
  },

  created () {
    this.buildUsersList()
  },
  mounted () {
    this.isMounted = true

  },

  watch: {
    // ROYBON / Charge les avatars
    portalUsers: function () {
      this.getAvatars()
      // log l'activité du user
      logActivity.add('portal_users', 'success')
    },
    // ROYBON / Vérifie si le nouvel utilisateur en passe d'être créé accède déjà au portal
    userCreation_user_id: function () {
      this.userCreation_user_exists_on_this_portal = false
      const params = `portal_id=${this.$store.state.portal_id}&user_id=${this.userCreation_user_id}&user_hash=${this.$store.state.AppActiveUser.user_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserPortalCaps}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => {
          if (response.data !== null && response.data != 'null') {
            if (response.data.can_portal_access === '1') {
              this.userCreation_user_exists_on_this_portal = true
            }
          }
          
        })
        .catch((error)   => { console.log(error) })      
    }
  }
}

</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .user_id {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
    .sidebar-tooltip {
      z-index: 999999;
    }
    .vs-tooltip {
      z-index: 999999;
    }
  }
}
</style>
